import * as React from 'react';
import axios from "axios";
import { useEffect, useState } from "react";
import './gallerycss.scss';
import Header from '../../Components/MRMComponent/Header';

const BASE_URL = process.env.REACT_APP_Image_src || 'http://82.180.139.171';
const axiosInstance = axios.create({baseURL:process.env.REACT_APP_API_URL});

export default function TitlebarImageList() {
  const [Images, setImages] = useState([]);
  const [updateUI, setUpdateUI] = useState("");

  useEffect(() => {
    axiosInstance.get('/gallery')
      .then((response) => {
        setImages(response.data);
      })
      .catch((error) => {
        console.error('Error fetching files:', error);
      });
  }, [updateUI]);

  return (
    <div>
     <section  className="container " >      
   <Header  Firsttxt="OUR"
          Secondtxt="GALLERY"/>
    <div className="row">
        {Images.map((image) => (  <div key = {image._id} className="col-xs-4 col-md-4  mt-5 py-0 col-lg-3 galleryimg">
          {/* <img src={`http://localhost:5000/Gallery/${image.file}`} className="img-fluid imgGrid" alt="image" /> */}
          <img src={`${BASE_URL}/Gallery/${image.file}`} className="img-fluid imgGrid" alt="image" />
        <div class="content">   
            <p class='text'>{image.name}</p>
        </div>
        </div>))}
       </div>
    </section>
    </div>
  )
  }
  


