import React from 'react'
import '../../App.css';
import "./home.scss";
import axios from "axios";
import { useEffect, useState } from "react";

const axiosInstance = axios.create({baseURL:process.env.REACT_APP_API_URL});

export default function HomeMeetingSlider(props) {
  const [AllMeetings, setAllMeetings] = useState([]);
 
// Function to format the date string as "DD-MM-YYYY"
  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('en-GB', {
      day: '2-digit',month: '2-digit',year: 'numeric',
    });
    const formattedDay = date.getDate();
    const formattedMonth = date.toLocaleString('en-us', { month: 'short' });
    const formattedYear = date.getFullYear();
    return {formattedDate,formattedDay,formattedMonth,formattedYear,
    };
  }

  useEffect(() => {
    axiosInstance
      .get('meetings/allmeetings')
      .then((response) => {
        const formattedMeetings = response.data.map((meeting) => ({...meeting,
          ...formatDate(meeting.meeting_date),
        }));
        setAllMeetings(formattedMeetings);
      })
      .catch((error) => {
        console.error('Error fetching files:', error);
      });
  }, []);


  return (
     
      <div id="carouselExampleInterval"  className="carousel slide" style={{ marginBottom: '0' }} 
      data-bs-ride="carousel">
  <div className="carousel-inner m-0">

  {AllMeetings.map((meetings) => (<div className="carousel-item active" key = {meetings._id} data-bs-interval="3000">
     
      <div className="slider">
        <div className='meet'>
        <h3>{meetings.title}</h3>    
        <p className="Sliderhost">Host  by  <span>{meetings.host}</span></p>
        <p className="sliderDate"><b>{meetings.formattedDate} </b> at <b>{meetings.meeting_time.from} - {meetings.meeting_time.to}</b></p>
      </div></div>
    </div> ))}
  </div>
  {/* <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button> */}
</div>
   
  )
}
