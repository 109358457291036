import React from 'react'
import "../../Pages/Resources/Resources.css"
import "./ResourcesComp.scss"
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import axios from "axios";
import useFetch from "../../Hooks/useFetch"
const BASE_URL = process.env.REACT_APP_Image_src || 'http://82.180.139.171';

export default function ResourceList(props) {
  
const item = props.item;
  

  return (
    <div>
    <section  className="container">         
   <div  className="r-card d-flex Resourelist justify-content-between">
        <div >
          <h7><span className='title'>{item.name} </span> - </h7>
          <div className= 'category'>{item.category}</div>
          {/* <div ><a href={`http://localhost:5000/Upload/${item.file}`} download={item.file}><span className='pdficon'><PictureAsPdfOutlinedIcon></PictureAsPdfOutlinedIcon>  </span><span className='pdffile'>  {item.file}</span></a></div> */}
          <div ><a href={`${BASE_URL}/Upload/${item.file}`} download={item.file}><span className='pdficon'><PictureAsPdfOutlinedIcon></PictureAsPdfOutlinedIcon>  </span><span className='pdffile'>  {item.file}</span></a></div>
         
        </div>
        <div className="Buttons-grp">
        {/* <a href={`http://localhost:5000/Upload/${item.file}`} type="button" className="btn button"><FileDownloadOutlinedIcon /></a> */}
        <a href={`${BASE_URL}/Upload/${item.file}`} type="button" className="btn button"><FileDownloadOutlinedIcon /></a>
        </div>
      </div>
    </section>
    </div>


  )

}



// const [files, setfiles] = useState([]);
  // const [updateUI, setUpdateUI] = useState("");
  // const { data, loading, error } = useFetch("/resources");

  // const filteredData = !selectedCategory ? data : data.filter(item => item.category === selectedCategory);
    

  // useEffect(() => {
  //   axios.get('/resources/allResources')
  //     .then((response) => {
  //       setfiles(response.data);
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching files:', error);
  //     });
  // }, [updateUI]);