import { useEffect, useState } from "react";
import axios from "axios";

const axiosInstance = axios.create({baseURL:process.env.REACT_APP_API_URL});

const useFetch = (url) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const formatDate = (dateString) =>{
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('en-GB', {
      day: '2-digit',month: '2-digit',year: 'numeric',
    });
    const formattedDay = date.getDate();
    const formattedMonth = date.toLocaleString('en-us', { month: 'short' });
    const formattedYear = date.getFullYear();
    return {formattedDate,formattedDay,formattedMonth,formattedYear,
    };
  }


  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try{
     const res =  await axiosInstance.get(url);
        const formattedMeetings = res.data.map((meeting) => ({...meeting,
          ...formatDate(meeting.meeting_date),
        }));
        setData(formattedMeetings);
      } 
      catch (err) {
        setError(err);
      }
      setLoading(false);
    };
    fetchData();
  }, [url]);
  return { data, loading, error};
}


// export const useFetch1 = (url) => {

//     const formatDate = (dateString) =>{
//         const date = new Date(dateString);
//         const formattedDate = date.toLocaleDateString('en-GB', {
//           day: '2-digit',month: '2-digit',year: 'numeric',
//         });
//         const formattedDay = date.getDate();
//         const formattedMonth = date.toLocaleString('en-us', { month: 'short' });
//         const formattedYear = date.getFullYear();
//         return {formattedDate,formattedDay,formattedMonth,formattedYear,
//         };
//       }

//     const [data, setData] = useState([]);
//     const [loading, setLoading] = useState(false);
//     const [error, setError] = useState(false);

//   const reFetch = async () => {
//     setLoading(true);
//       try{
//      const res =  await axios.get(url);
//         const formattedMeetings = res.data.map((meeting) => ({...meeting,
//           ...formatDate(meeting.meeting_date),
//         }));
//         setData(formattedMeetings);
//       } 
//       catch (err) {
//         setError(err);
//       }
//       setLoading(false);
//     };

//   return {loading, error, reFetch };
// }

export default useFetch;
