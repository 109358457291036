import React from 'react';
import "./MemberComp.scss";
import CallIcon from '@mui/icons-material/Call';
import LanguageIcon from '@mui/icons-material/Language';

// import "../../Pages/Resources/Resources.css"


const  MembersList = (props) => {
  const members = props.members
  // const [AllMembers, setAllMembers] = useState([]);
  // const { data, loading, error } = useFetch("/members");
 return (
  <div>
    <section className='container'>
       <div className="r-card d-flex Meetevent justify-content-between">
        <div className='firstGrid'>
          <h7>{members.organisation}</h7>
          <div className='contact'>
            <p id="text" style={{ fontSize: '0.9rem' }}><CallIcon className="icon" style={{ fontSize: '1rem' }}></CallIcon> &nbsp; {members.phone}</p>
            <p className='weblink' id="text" style={{ fontSize: '0.9rem', minlength:"20"}}><LanguageIcon className="icon"  style={{ fontSize: '1rem' }}></LanguageIcon> &nbsp; {members.weburl}</p>
          </div>
          
        </div>
        <div className="Buttons-grp secondGrid">
        <a type="button" href={members.weburl} className="btn button"><LanguageIcon/></a>
        </div>
      </div>
    </section>
   </div>
  )}

export default MembersList

