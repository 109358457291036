import React from 'react'
import ListAboutus from '../../Components/Aboutus/ListAboutus';
import Header from "../../Components/MRMComponent/Header.js"
import './About.scss';
import image from "../../Images/40.JPG"

export default function Aboutus() {
  return (
    <div className='container Aboutus'>
      <div className='about-us'  >
        <Header Firsttxt="WHO" Secondtxt="WE ARE" />
      </div>

      <div className="content">
        <div className="Header">
        <h3 className='text-center heading '>Canterbury Bankstown Multicultural Interagency</h3>
      </div>

      <div className="Intro">
        <p>
          The Canterbury Bankstown Multicultural Interagency (CBMIA) is a community cross sector forum which comprises representatives of non-government and government organisations and private service providers in the Canterbury and Bankstown LGA’s. CBMIA is jointly convened by The Multicultural Network (TMN) and Metro Assist. The CBMIA meets monthly and alternates between Metro Assist Campsie and Bankstown Library and Knowledge Centre.
        </p></div>

       
    <div className="aimContent"> 
    <div className="principleContent">
        <div className="firstPrinciple "><h6 className='smallHead'>Principles of the CBMIA  </h6> <p>to adhere to democratic principles and practices of community development.</p></div>
        <div className="secondAim "><h6 className='smallHead'>Aims  </h6>
        <ul> <li>To identify issues, needs and gaps in services, co-ordinate strategies etc. To improve service delivery
          to CALD communities, refugees and humanitarian entrants in the City of Canterbury Bankstown.</li>
          <li>Provide an avenue for exchange of information and a forum for discussion on issues of concern for
            communities, in partnership with Government and community sectors and across LGAs.</li>
          <li>Provide an overview of issues and respond as required, on issues affecting CALD, Refugee and
            Humanitarian Entrants/ clients and communities in relation to policy changes, service delivery and
            resource allocation to the area.</li></ul></div>
        <div className="thridObjec"><h6 className='smallHead'>Objectives  </h6>
        <ul> <li>Improve networking and co-ordination of services amongst community groups and organisations,
          peaks and advocacy groups.</li>
          <li>Improve knowledge and skills of workers by organizing guest speakers, relevant workshops and
            seminars.</li>
          <li>Facilitate working groups to develop an integrated response on issues affecting CALD, Refugee and
            Humanitarian Entrants communities in order to advocate for social justice and equity.</li></ul></div>
            </div>
           <div className="image">
            <img src={image} alt="" />
           </div>
     </div>   
<hr />
        
        <div className="terms">
          <h4 className='text-center bigHead'>Terms of Reference</h4>
          <h6 className='smallHead'>Participating Agencies </h6>
           <p>Participation in the Interagency will be sought from a representative of all agencies that work with
            CALD communities, refugees and humanitarian entrants in the City of Canterbury Bankstown.</p>
          <h6 className='smallHead'>Conveners</h6> <p>The Multicultural Network Inc. and Metro Assist Inc are host bodies/fund holders and co-convene the
            Canterbury Bankstown Multicultural Interagency</p>
          <p>The conveners will have responsibility for organising and distributing agendas, minutes and notices of
            meetings and for maintaining mailing lists. They will provide administration, follow-up and co-ordination
            support to the Interagency.</p>
        </div>

<hr />

        {/* Meeting Processes */}
        <div className="listItem">
        <h4 className='text-center pb-4 bigHead'>Meeting processes </h4></div>
        
        {/* <ListAboutus /> */}


        {/* <h6> <u>Meeting agendas</u></h6>
        <p>The convener will call for agenda items approximately 2 weeks before the meeting and will collate these
          and send out an agenda approximately one week before the meeting.</p>

        <p>Agendas will include:</p>
        <ul>
          <li>Introduction /Apology</li>
          <li>Relational meeting</li>
          <li>Business arising from the previous minutes</li>
          <li>Confirmation of previous minutes</li>
          <li>Standing items: <ul>
            <li>Reports from the working parties and other related interagency</li>
            <li>Service information updates including new initiatives/projects/research</li>
            <li>Guest speakers, training and development opportunities</li>
          </ul></li>
          <li>Next meeting</li>
        </ul>
        <h6><u>Quoram</u></h6>
        <p>A minimum of 6 attendees from different agencies are required in order to make decisions. Decisions
          will be made by majority vote with 1 vote per agency in attendance at the meeting.</p>
        <h6><u>Chairperson</u></h6>
        <p>The role of chairperson shall be rotated amongst participants and allocated by calling for a volunteer as
          required at each meeting.</p>

        <p>The chairperson is responsible for ensuring that the meeting works through the agenda in the allocated
          time and that participants have the opportunity to contribute to the discussion about any given agenda
          item. This responsibility is shared with others in attendance.</p>

        <h6><u>Minutes</u></h6>
        <p>The role of minute taker shall be rotated amongst conveners or participants and the person nominated will
          be placed on the meeting roster. A minute taker may also be allocated by calling for a volunteer as
          required at each meeting.</p>

        <p>The minute taker will compile the minutes and email them to the convener for distribution and will be
          distributed within 3 weeks after the meeting.</p>

        <h6><u>Decision making</u></h6>
        <p>As per quorum – see above.</p>
        <p>Where decisions between meetings are required, the convener shall email members giving a concise
          explanation of the issue as discussed at the meeting and requesting that they vote in favor or against by a
          given date. A vote in favor by 6 members from different agencies is required in order to make a decision.</p>

        <h6><u>Working parties</u></h6>
        <p>From time to time, working parties that meet outside of the regular meeting time shall be established in
          order to work on projects such as planning events, seminars, training etc. A convener of the working
          party will be nominated at the start of meeting and a work plan including aims, objectives and intended
          outcomes are identified. Report/minutes of all meetings will be emailed to the CBMIA convener to
          distribute with the monthly minutes so that members can have input into the working party or tabled at a
          CBMIA meeting whichever is sooner.</p>

        <h6><u>Information sharing</u></h6>
        <p>The convener will provide a form at the back of the minutes on which service providers can record
          information to share about services and projects. Completed forms and or brochures or flyers can be
          handed to the minute taker at the meeting or service information may be emailed to the convener within a
          few days of the meeting and will assist in ensuring that service information is recorded accurately in the
          minutes. This information will be distributed with the minutes.</p>

        <p>Each service/agency shall be allocated a few minutes to share 1 or 2 items with the group. Information
          sharing by individual service providers should proceed without interruption. Questions will be taken at
          the end of the service’s allocated time before proceeding to the next speaker. <u>All other information may
            be added to the Information Sharing Sheet and will be included with the minutes.</u></p>
        <h6><u>Small group discussion</u></h6>
        <p>Where appropriate, small groups will be formed to discuss issues before reporting to the large group.</p>
        <h6><u>Guest speakers</u></h6>
        <p>The Interagency will decide to invite a guest speaker to discuss a topic of interest to the majority of the
          group. For example, the meeting may be interested in knowing more about a new project in the area. If
          the convener is approached by a person who would like to address the Interagency, the convener will
          consult this at a meeting or if necessary, the CBMIA co-convener about whether to have this added to the
          agenda.</p>
        <h6><u>Training/workshops/seminars</u></h6>
        <p>The Interagency may decide to organize specific training/workshops/seminars for members depending on
          the availability of resources and funds. A working party may form to decide the scope of the sessions,
          engage a trainer/s /facilitator/s and discuss a final decision at a regular CBMIA meeting for the final
          confirmation of details.</p>
        <h6><u>Meeting evaluation</u></h6>
        <p>Meeting processes will be evaluated annually in February by discussion at the end of a meeting and a
          questionnaire may be circulated, for practical purposes to members.</p> */}

<hr /> 
<div className="meetingInfo terms">   
        <h6 className='smallHead'><b>Meeting frequency, date and time</b></h6>
        <p>Meetings will generally be held on monthly basis on the second Friday of the month at 10:00 am online or
          face to face. The meetings can change or cancel at any time. The members will be updated via E-mail.</p>
        <h6 className='smallHead'><b>Meeting venue</b></h6>
        <p>The venue will rotate between Bankstown (Bankstown Library and Knowledge Centre) and Metro Assist
          Campsie or online using virtual platform.</p>
        <h6 className='smallHead'><b>Reporting</b></h6>
        <p>The Interagency presentation is presented at the end of the year at City of Canterbury Bankstown end of
          year service get together.</p>
        <h6 className='smallHead'><b>Participation in community events</b></h6>
        <p>The Interagency will endeavor to be represented at community events, either by individual services or
          collectively, such as, Anti poverty week, International Women’s Day celebrations, Refugee Week events
          and other related events</p>
        <h6 className='smallHead'><b>Pandemic</b></h6>
        <p>Due to the pandemic situation the meeting may be cancelled or there may be changes in the meeting time,
          venue or date. CBMIA members are required to collaborate with the convenors in case of any unexpected
          changes.</p>
        <h6 className='smallHead'><b>CBMIA attendees should follow the NSW Health guideline at all times.</b></h6>
        <h6 className='smallHead'><b>Terms of Reference Review</b></h6>
            <p>The Terms of Reference will be reviewed at the Interagency meeting in February each year.</p>
</div> 
      </div>
    </div>
  )
}
