import React from 'react'
import '../../App.css';
import "./home.scss";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import SettingsPhoneOutlinedIcon from '@mui/icons-material/SettingsPhoneOutlined';

export default function HomeContact() {
    return (
        <>
            <div className="container">
                <div className="row card-body contactcards">

                    <div className="col-md-6 mt-3">
                        <div className="card card-contact align-items-center">
                            <div className="card-horizontal ">
                                <div className="card-body">
                                    <h4 className="">Sabita Pudaisini</h4>
                                    <p className="card-office">The Multicultural network</p>

                                    <h6 className="card-text"> <EmailOutlinedIcon className="icon"></EmailOutlinedIcon> &nbsp; mcdp@tmn.net.au </h6>
                                    <h6 className="card-text"> <SettingsPhoneOutlinedIcon className="icon"></SettingsPhoneOutlinedIcon> &nbsp; 0450136380 </h6>
                                    <a href='/Contactus' type="button" className="btn btn-contact">More &nbsp; <i className="fas fa-chevron-right"></i> </a>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-md-6 mt-3">
                        <div className="card card-contact align-items-center">
                            <div className="card-horizontal">
                                <div className="card-body">
                                    <h4 className="">Jasmine Mguizra</h4>
                                    <p className="card-office">Metro Assist</p>

                                    <h6 className="card-text"><EmailOutlinedIcon className="icon"></EmailOutlinedIcon>  &nbsp; Jasmine.Mguizra@metroassist.org.au </h6>
                                    <h6 className="card-text"> <SettingsPhoneOutlinedIcon className="icon"></SettingsPhoneOutlinedIcon> &nbsp; 0434423752 </h6>
                                    <a href='/Contactus' type="button" className="btn btn-contact">More &nbsp; <i className="fas fa-chevron-right"></i> </a>
                                </div>
                            </div></div> </div>

                </div>
            </div>
        </>
    )

}
