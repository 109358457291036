import React from 'react'
import "../../Pages/Resources/Resources.css";
import "./MRM.scss";



export default function Header(props) {

  return (
    <div classname="conatiner">
      <div className="header">
      <p className='head-Contact pt-5'><span className="text-danger" id="abt">{props.Firsttxt}</span> <span className="text-info" id='pipe'>| </span><span className="text-warning" id='us'>{props.Secondtxt}</span></p>

      {/* <h1>{props.Heading}</h1> */}
      <p>{props.Summary}</p>
      </div>
    </div>
  )
}
