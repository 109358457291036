import React from 'react'
import collage from '../../Images/collage3.png';
import '../../App.css';
import "./home.scss";

export default function HomeAbout() {
  return (


 <div className="container-fluid row " id="abouthome">

  <div className="content-left  col-lg-7">
    {/* TITLE */}
    <h2><span className="text-danger" id="abt">ABOUT </span> <span className="text-info" id='pipe'>| </span><span className="text-warning" id='us'>US</span></h2>
    {/* CONTENT */}
    <div className="description-short"><p>The Canterbury Bankstown Multicultural Interagency (CBMIA) is a community cross sector forum which
      comprises representatives of non-government and government organisations and private service providers
      in the Canterbury and Bankstown LGA’s. CBMIA is jointly convened by The Multicultural Network
      (TMN) and Metro Assist. <span id="hidabout">The CBMIA meets monthly and alternates between Metro Assist Campsie and
      Bankstown Library and Knowledge Centre.</span></p></div>
    <a href="/Aboutus" type="button" className="btn btn-about">Read More &nbsp; <i className="fas fa-chevron-right"></i> </a>
  </div>
   {/* GRID IMAGE */}
  <div className=" conent-right col-lg-5 ">
    <img id="thumbs" src={collage} className="rounded float-left" alt="About us" />
  </div>
  
</div> 

  )
}
