import React from 'react';
import "../../Styles/Footer.css" // Import the CSS file for the footer
import metro from '../../Images/metro logo3.png';
import tmn from '../../Images/TMN logo2.png';


    
    function Footer() {
      return (  
    
    <footer className="footer-section">
            <div className="container">
                <div className="footer-content pt-5 pb-5">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 mb-50">
                            <div className="footer-widget logo-section row">
                                <div className="footer-logo col-6 col-lg-12">
                                    <a href="https://www.metroassist.org.au/#"><img className="logometro"src={metro} alt="Logo 1" /></a>
                                </div>
                                <div className="footer-logo tmn col-6 col-lg-12">
                                <a href="http://tmn.net.au/"><img className="logotmn"src={tmn} alt="Logo 2" /></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 mb-30 mt-3">
                            <div className="footer-widget">
                                <div className="footer-widget-heading">
                                    <h3>Useful Links</h3>
                                </div>
                                <ul>
                                    <li><a href="/">Home</a></li>
                                    <li><a href="/Aboutus">About us</a></li>
                                    <li><a href="/Contactus">Contact us</a></li>
                                    <li><a href="/Resources">Our Resources</a></li>
                                    <li><a href="/Members">Our Members</a></li>
                                    <li><a href="/Meetings">Meetings</a></li>
                                    <li><a href="/Gallery">Our Gallery</a></li>
                                    
                                    
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-2 col-md-6 mb-50 mt-3">
                        <div className="footer-social-icon">
                                    <span>Follow us</span>
                                    <a href="/"><i className="fab fa-facebook-f facebook-bg"></i></a>
                                    <a href="/"><i className="fab fa-google-plus-g google-bg"></i></a>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copyright-area">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 col-lg-8 text-center text-lg-left">
                            <div className="copyright-text">
                               <p ><b>This Product is funded by City Caterbury-Bankstown</b></p>
                                <p>Copyright &copy; 2023, All Rights reserved </p>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 d-none d-lg-block text-right">
                            <div className="footer-menu">
                                <p>Developed by <b>Monika Mangal</b></p>
                                
    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
      );
    }
    
    export default Footer;
    