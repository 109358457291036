import React, {useState, useEffect}from 'react'
import "./Resources.css";
import ResourcesList from "../../Components/Resources/ResourceList.js"
import Header from "../../Components/MRMComponent/Header.js";
import useFetch from "../../Hooks/useFetch";
// import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
// import ListIcon from '@mui/icons-material/List';
// import DropdownList from '../../Components/DropdownResource'

export default function Resources() {
  //------------------------------------------------------------------
  const [selectedCategory, setSelectedCategory] = useState(null);
 //DROPDOWN STATES AND FUNCTION
  const [selectedType, setSelectedType] = useState("");
  const [SearchInput, setSearchInput] = useState("");
  
const { data, loading, error, reFetch } = useFetch("/resources");
// const { data, loading, error, reFetch } = useFetch(!selectedType? '/resources' : `resources/search?search=${SearchInput}&category=${selectedType}`);
  /*`resources/search?search=${SearchInput}&category=${selectedType}`*/
  const searchClick= () => {
    console.log("Hello")
   };
 
  const handleCategoryChange = (category) => {
   console.log("Hello")
  };

  
  const handleTypeChange = (e) => {
    const value = e.target.value;
    setSelectedType(value);   
  };

  useEffect(() => {
    
  }, [selectedType]);
  
 
//------------------------------------------------------------------------
  return (
  <div classsName="container container-style">
     {/* Heading */}
      <Header
         Firsttxt="OUR"
         Secondtxt="RESOURCES"
        Summary="Please view and Download Our Resources (Documents)">
      </Header>
      <div className=" container Resource-list">
  {data && data.map((item) => ( <ResourcesList item={item} key = {item._id}></ResourcesList>))}
  </div>

 </div>
  )
}
          {/* SEARCHBAR  */}
      {/* <div className="searchbar mt-5 container container-style d-flex justify-content-around" > */}
        {/* SEARCHINPUT  */}
        {/* <form className="form d-inline">
          <input 
          className="form-control mr-sm-2" 
          type="search" 
          placeholder="Search" 
          aria-label="Search"
          onChange={(e) => setSearchInput(e.target.value)}
           />
        </form> */}
        {/* DROPDOWN FOR CATEGORY FILTER*/}
        {/* <div className="dropdown">
          <select id="resources" placeholder="Search" name="category" onChange={handleTypeChange}>
            <option value=''>All Category (select)</option>
            {data && data.map((item) => (
              <option key={item._id} value={item.category}>
                {item.category}
              </option>
            ))}
          </select>
        </div>
        <button type='submit' onClick={searchClick}>search</button>
      </div> */}
        
  


{/* <div className="container-style View container d-inline">
            <a href="/" className="list"><ListIcon></ListIcon>   </a> 
            <a href="/" className="grid">  <GridViewOutlinedIcon></GridViewOutlinedIcon></a>
          </div> */}

               {/* <button className="btn btn-outline-success my-sm-0" type="search">Search</button> */}
               {/* <DropdownList></DropdownList> */}