import React from 'react'
import HomeColorCards from '../../Components/Home/HomeColorCards'
import HomeMeetingSlider from '../../Components/Home/HomeMeetingSlider'
import HomeAbout from '../../Components/Home/HomeAbout'
import HomeContact from '../../Components/Home/HomeContact'



export default function Home() {
  return (
   <>
   <HomeColorCards />
    <HomeAbout />
    <HomeMeetingSlider />
   <HomeContact />
   
   </>
  )
}
