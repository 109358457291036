import React from 'react'
import "../../Pages/Meeting/Meetings.scss"
import "./meetingComp.scss"
import PersonIcon from '@mui/icons-material/Person';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined';
import InsertInvitationOutlinedIcon from '@mui/icons-material/InsertInvitationOutlined';
import axios from "axios";
import { useEffect, useState } from "react";
import useFetch from '../../Hooks/useFetch2';




export default function MeetingList(props) {

  const [AllMeetings, setAllMeetings] = useState([]);

  const { data, loading, error} = useFetch('/meetings/');
 
  return (
<div>
    {data.map((meetings) => ( <div key = {meetings._id} className='container meeting-page d-inline-flex'>
 {/* Wrapped data on side */}
 <div className="event-date-tag">
    <div>
  <time className="event-date-tag-datetime" datetime="23-07-2023" aria-hidden="true">
    <span className="event-date-tag-month">{meetings.formattedMonth}</span>
    <span className="event-date-tag-daynum">{meetings.formattedDay}</span>
    <span className="event-date-tag-year">{meetings.formattedYear}</span>
  </time></div>
</div>

< div className="event-card">
  <div className="event-datetime">
    {/* Date and Time above Title*/}
    <span className="event-date-start"><InsertInvitationOutlinedIcon className="iconcolor" style={{fontSize:'1rem'}}></InsertInvitationOutlinedIcon>{meetings.formattedDate} &nbsp;</span><br className='d-lg-none'></br><span><ScheduleOutlinedIcon className="iconcolor" style={{fontSize:'1rem'}}></ScheduleOutlinedIcon>{meetings.meeting_time.from}</span> - <span className="event-time">{meetings.meeting_time.to}</span>
  </div>
  {/*Title*/}
  <p className="event-title event-title-link">{meetings.title}  </p>
  {/* Host and Place*/}
  <div className="host-place">
    <div className="event-venue">
    <span className="event-venue-title"><PersonIcon className="iconcolor" style={{fontSize:'1.2rem'}}></PersonIcon>  Host: {meetings.host}</span>
  </div>
  <div className="event-venue place">
    <span className="event-venue-title"> <FmdGoodIcon className="iconcolor" style={{fontSize:"1rem"}}></FmdGoodIcon>  Place: {meetings.place}</span>
  </div>
  </div>
  {/* Discription*/}
  <div className="event-description my-2">
    <p>  <DescriptionOutlinedIcon className="iconcolor" style={{fontSize:'1.2rem'}}></DescriptionOutlinedIcon> Details: {meetings.desc}</p>
  </div>
</div>

    </div>))}

    </div>
  )
}
