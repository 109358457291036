import React from 'react'
import './ContactUs.css';
import Contact from "../../Components/Home/HomeContact"
import Header from "../../Components/MRMComponent/Header.js"

export default function Contactus() {
  return (
    <div className='container'>
      <div className='contact-us'>
    <Header Firsttxt="CONTACT" Secondtxt="US"/>
    </div>
<Contact />

    </div>
  )
}
