import React from 'react'
import "../../App.css"
import "../Root/RootComp.scss"

export default function Banner(props) {
  return (
    <section>
        <div id="carouselExampleInterval" className=" carousel slide" style={{ marginBottom: '0' }} data-bs-ride="carousel">
          <div className="carousel-inner m-0">
            {/* image1 */}
            <div className="carousel-item active" data-bs-interval="3000">
              <img className="d-block banner" src="/" alt="First slide" />
            </div>
           {/* image 2*/}
            <div className="carousel-item" data-bs-interval="3000">
              <img className="d-block banner2" src="/" alt="First slide" />
            </div>
             {/* image 3 */}
            <div className="carousel-item" data-bs-interval="3000">
              <img className="d-block banner3" src="/" alt="First slide" />
            </div>
          </div>
        </div>
    
    </section>
  ) 
}
