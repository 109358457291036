import React from 'react'
import image from '../../Images/CBMIAlogo.jpg';
import "../../App.css"
import "./RootComp.scss";


export default function Navbar() {
  return (

    <nav className="navbar fixed-top navbar-expand-lg bg-body-tertiary">      
      <div className="container-fluid">
         {/* LOGO */}
        <a className="navbar-brand" href="/">
          <img src={image} width="30" height="30" className="logo d-inline-block align-top" alt="" />  </a>
          {/* HAMBURGER */}
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse py-3" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mr-auto" id='navlinks'>
            <li className="nav-item active">
              <a className="nav-link active" href="/">Home </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/Members">Members</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/Meetings">Meetings</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/Resources">Resources</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/Gallery">Gallery</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/Aboutus">AboutUs</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/Contactus">ContactUs</a>
            </li>
          </ul></div>
          
      </div>
    </nav>

  )
}