import React, { useEffect, useState } from "react";
import { DateRange } from "react-date-range";
import useFetch from "../../Hooks/useFetch";
import "../Resources/Resources.css";
import "./Meetings.scss";
import Header from "../../Components/MRMComponent/Header.js";
import MeetingList from "../../Components/Meeting/MeetingList";
// import ResourceDropdown from "../../Components/ResourceDropdown";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { format } from "date-fns";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined';
import InsertInvitationOutlinedIcon from '@mui/icons-material/InsertInvitationOutlined';
import PersonIcon from '@mui/icons-material/Person';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
// import Event from "@mui/icons-material/EventAvailable";
import EventBusy from "@mui/icons-material/EventBusy";
// import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
// import ListIcon from "@mui/icons-material/List";

export default function Meetings() {
  const [SearchInput, setSearchInput] = useState("");
  const [openDate, setOpenDate] = useState(false);
  const [dates, setDates] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
   const { data, loading, error } = useFetch('/meetings/upcoming');
  const handleSearch = () => {
    console.log(dates[0].startDate);
    console.log(dates[0].endDate);
    console.log(SearchInput)
  };

  const datecloseclick = (e) =>
  {
    e.preventDefault()
    setOpenDate(!openDate)
  }

  return (
    <div className="meetbody" >
      <div className="header">
        {/* HEADER */}
        <Header
          Firsttxt="MEETING"
          Secondtxt="EVENTS"
          Summary="Please see the upcoming meetings and search by topic or date."
        ></Header>

 {/* UPCOMING MEETINGS */}
        <div className="container upcoming-meeting div1">
          {data && data.length > 0 ? (<>{data.map((meetings) => (<div className=" container text-center upcoming-event ">
            <p className="event-title event-title-link">{meetings.title}  </p>
            <div className="event-datetime">
              <span className="event-date-start"><InsertInvitationOutlinedIcon className="iconcolor" style={{ fontSize: '1rem' }}></InsertInvitationOutlinedIcon>  {meetings.meeting_date ? meetings.meeting_date.split('T')[0] : 'Invalid Date'} &nbsp;</span><br className='d-lg-none'></br><span><ScheduleOutlinedIcon className="iconcolor" style={{ fontSize: '1rem' }}></ScheduleOutlinedIcon>  {meetings.meeting_time.from}</span> - <span className="event-time">{meetings.meeting_time.to}</span>
            </div>
            <div className="host-place d-flex justify-content-center">
              <div className="event-venue">
                <span className="event-venue-title"><PersonIcon className="iconcolor" style={{ fontSize: '1.2rem' }}></PersonIcon>  Host: {meetings.host}</span>
              </div>
              <div className="event-venue place">
                <span className="event-venue-title"> <FmdGoodIcon className="iconcolor" style={{ fontSize: "1rem" }}></FmdGoodIcon>  Place: {meetings.place}</span>
              </div>
            </div>
          </div>))} </>) : 
          
          (<> {<div className=" container text-center upcoming-event div2 ">
            <EventBusy></EventBusy> No Upcoming Meeting
          </div>} </>)} </div>
          </div>
      <div className=" container meeting-container">
       <MeetingList ></MeetingList></div>
</div>
);
}







        {/* SEARCH AND DATERANGE FILTER */}
        {/* <div className="container">
          <div className="headerSearch">
            <div className="headerSearchItem">
              <SearchOutlinedIcon className="headerIcon" />
              <input
                type="text"
                placeholder="Search Meeting"
                className="headerSearchInput"
                onChange={(e) => setSearchInput(e.target.value)}
              />
            </div>
            <div className="headerSearchItem">
              <CalendarMonthOutlinedIcon className="headerIcon" />
              <span
                onClick={datecloseclick}
                className="headerSearchText"
              >{`${format(dates[0].startDate, "MM/dd/yyyy")} to ${format(
                dates[0].endDate,
                "MM/dd/yyyy"
              )}`}</span>
              {openDate && (
                <DateRange
                  editableDateInputs={true}
                  onChange={(item) => setDates([item.selection])}
                  moveRangeOnFirstSelection={false}
                  ranges={dates}
                  className="date"
                  minDate={new Date()}
                />
              )}
            </div>
            <div className="headerSearchItem">
              <button className="headerBtn" onClick={handleSearch}>
                Search
              </button>
            </div>
          </div>
        </div> */}
     

      {/* <div className=" container">
      <div className="searchbar container container-style" >
        <div className="form-group col-md-6 inputs" >
          <input 
            className="form-control "
            type="search"
            placeholder="Search"
            aria-label="Search"
          />
        </div>
        <div className="col-md-4 inputs"></div>
        <DateRange
  editableDateInputs={true}
  onChange={item => setState([item.selection])}
  moveRangeOnFirstSelection={false}
  ranges={state}
/> </div> 
</div> */}

      

   // const [upcomingeetings, setupcomingmeet] = useState([]);

  // useEffect(() => {
  //   axios.get('meetings/upcoming')
  //     .then((response) => {
  //       setupcomingmeet(response.data);
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching files:', error);
  //     });
  // }, []);;
     

    // const { data, loading, error } = useFetch(`/meetings?fromDate=${dates[0].startDate}&toDate=${dates[0].endDate}`);
  // const { data, loading, error, reFetch } = useFetch("meetings/upcoming");
  // const filteredData = !selectedCategory ? data : data.filter(item => item.category === selectedCategory);