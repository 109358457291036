
import React from 'react'
import './App.css';
import Navbar from "./Components/Root/Navbar.js";
import Banner from "./Components/Root/Banner.js";
import banner1 from "./Images/BannerCrop.JPG";
import banner2 from "./Images/Bannercrop2.png";
import Home from "./Pages/Home/Home.js";
import Footer from './Components/Root/Footer.js';
import Members from './Pages/Members/Members.js';
import Meetings from './Pages/Meeting/Meetings.js';
import Resources from './Pages/Resources/Resources';
import Gallery from './Pages/Gallery/Gallery';
import Aboutus from "./Pages/Abotus/Aboutus";
import Contactus from './Pages/Contactus/Contactus.js';
import { createBrowserRouter, RouterProvider, } from "react-router-dom";



const router = createBrowserRouter([
  { path: "/", element: <Home /> },
  { path: "/Members", element: <Members /> },
  { path: "/Meetings", element: <Meetings /> },
  { path: "/Resources", element: <Resources /> },
  { path: "/Gallery", element: <Gallery /> },
  { path: "/Aboutus", element: <Aboutus /> },
  { path: "/Contactus", element: <Contactus /> },
  // {path: "/Admin",element: <Login />},
  // {path: "/Meetingupload",element: <MeetingUpload />}
]);

function App() {
  return (

    <div className="App">
      <Navbar />
      <Banner image1={banner1} image2={banner2} />

      <React.StrictMode>
        <RouterProvider router={router} />
      </React.StrictMode>

      <Footer />
    </div>
  );
}

export default App;
