import React, {useState, useEffect} from 'react'
import axios from 'axios';
import "../Resources/Resources.css"
import MembersList from "../../Components/Member/MembersList";
import Header from "../../Components/MRMComponent/Header.js"
import Searchbar from '../../Components/MRMComponent/Searchbar';
import useFetch from '../../Hooks/useFetch'

// import ListIcon from '@mui/icons-material/List';
// import DropdownList from '../../Components/DropdownResource';
// import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';

const axiosInstance = axios.create({baseURL:process.env.REACT_APP_API_URL});

export default function Members() {
  const [membersCount, setMembersCount] = useState(0);

  const [membersData, setMembersData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1); // Initialize the page number

  // const { data, loading, error } = useFetch("/members");
  const { data, loading: newLoading, error: newError } = useFetch(`/members/members?page=${page}`);

  useEffect(() => {
    if (newLoading || !data) return;
    console.log(`Received data for page ${page}:`, data);
    setMembersData((prevData) => [...prevData, ...data]);
    setLoading(false);
    setError(newError);
  }, [newLoading, data, newError]);

  const handleScroll = () => {
    const scrollTop = document.documentElement.scrollTop;
    const offsetHeight = document.documentElement.offsetHeight;
    const innerHeight = window.innerHeight;

    if (scrollTop + innerHeight >= offsetHeight - 200 && !loading) {
      // Load more data when the user is near the bottom
      setPage((prevPage) => prevPage + 1);
      setLoading(true);
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

 useEffect(() => {
    // Fetch members count from your API endpoint using Axios
    axiosInstance.get('members/count/my') // Replace this with your actual API endpoint
      .then(response => {
        const data = response.data;
        setMembersCount(data.count); // Assuming the count is returned as 'count'
      })
      .catch(error => {
        console.error('Error fetching members count:', error);
      });
  }, []);


  return (
    <div>
      <Header
        Firsttxt="OUR"
        Secondtxt="MEMBERS"
        Summary={<span>We have over{' '} <span style={{ fontWeight: 'bold', color: 'red' }}>  {membersCount} </span>{' '}
            organisational members. You can see the list below alphabetically.</span>}
      ></Header>
      <div className=" container">
        <div className="container Resource-list">
          {membersData.map(members => (
            <MembersList members={members} key={members._id} />
          ))}
        </div>
      </div>
    </div>
  );
}










  {/* 
      <div className="searchbar container container-style " >
        <div className="form-group col-md-8 inputs" >
          <input 
            className="form-control "
            type="search"
            placeholder="Search"
            aria-label="Search"
            value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
        <div className="col-md-4 inputs" ><DropdownList ></DropdownList> </div>
        
        <div className="col-2">
        <button
          type="button"
          className="btn btn-success"
          id="searchbtn"
          onClick={handleSearch}
        >
          Search
        </button>
      </div>
      </div>
      
</div> */}