import React from 'react'
import '../../App.css';
import './home.scss';

export default function HomeColorCards() {
  return (
    <div id="Banner-details">
      <div className="row d-flex justify-content-around color-grid" >

        <div className="card bannerdetail card4 col-3  text-white  mb-3 ">
          <div className="card-body text-break text-sm-2">
            <p className="card-title ">Capacity
              <br></br>Building</p>
          </div> </div>

        <div className="card bannerdetail card1 col-3 text-white   mb-3" >
          <div className="card-body">
            <p className="card-title">Information <br></br> Sharing</p>
          </div></div>

        <div className="card bannerdetail card2 col-3  text-white  mb-3" >
          <div className="card-body">
            <p className="card-title">Opportunity to <br></br> Network</p>
          </div></div>

        <div className="card bannerdetail card3 col-3  text-white mb-3" >
          <div className="card-body">
            <p className="card-title">Advocacy</p>
          </div></div>

      </div></div>
  )
}
